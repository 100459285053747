import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <div className="header">
              <a className="nav-link" href="/">
                <img src="favicon.png" className="home-icon"></img>
              </a>
              <a className="nav-home" href="/">
                Staking 247
              </a>
              <a className="nav-dashboard" href="https://monitor.staking247.com/d/7zlQNuigk/staking-247-node-dashboard?orgId=1&refresh=30s">
                Dashboard
              </a>
        </div>

        <section className="call-to-action text-white text-center">
          <div className="overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-xl-9 mx-auto">
                <h2 className="mb-4">Stake your ADA 24 / 7 with us.</h2>
                <h3 className="mb-4">Ticker: 247</h3>
                <h4 className="mb-4">Pool ID : <a style={{color:'white'}} href="https://pooltool.io/pool/4f04025065314e75684cd38357ac6af703fcef705d9b4f638e3caf46">4f04025065314e75684cd38357ac6af703fcef705d9b4f638e3caf46</a></h4>
                <h4 className="mb-4">Join our <a style={{color:'white'}} href="https://t.me/joinchat/LighTByX77x1W-q0O0i7AA"><i>telegram group</i></a> to get latest updates!</h4>
              </div>
            </div>
          </div>
        </section>

        <section className="features-icons text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-4">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <div className="features-icons-icon d-flex">
                    <i className="material-icons m-auto">
                      ₳
                    </i>
                  </div>
                  <h4>Profitability</h4>
                  <div className="lead" style={{textAlign: 'center'}}>
                    Staking<sub>247</sub> aims at maximizing the rewards for stake holders. We only charge a small amount of pool fee (0.5% margin, and minimum cost) to keep our infrastructure running.
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <div className="features-icons-icon d-flex">
                    <i className="material-icons m-auto">
                      trending_up
                    </i>
                  </div>
                  <h4>Performance</h4>
                  <ul className="lead" style={{textAlign: 'center'}}>
                    With years of enterprise software experience, our team is able to provide one of the most reliable staking pools.
                  </ul>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-4">
                <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                  <div className="features-icons-icon d-flex">
                    <i className="material-icons m-auto">
                      search
                    </i>
                  </div>
                  <h4>Transparency</h4>
                  <div className="lead mb-2" style={{textAlign: 'center'}}>
                    Checkout our node <a href="https://monitor.staking247.com/d/7zlQNuigk/staking-247-node-dashboard?orgId=1&refresh=30s">dashboard</a> to monitor real-time node status 
                    and join our <a href="https://t.me/staking247">telegram group</a> to get latest updates.
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                  <div className="features-icons-icon d-flex">
                    <i className="material-icons m-auto">
                      code
                    </i>
                  </div>
                  <h4>Open-source</h4>
                  <ul className="lead" style={{textAlign: 'center'}}>
                    We are actively contributing to open-source projects in Cardano's ecosystem. Checkout 
                    our Github repos <a href="https://github.com/cffls">here</a>.
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </section>

        <div className="push"></div>
      </div>
      <footer className="footer">
          <div className="container">
            <a href="https://twitter.com/247_pool"><i className="fab fa-twitter"></i></a>
            <a href="https://t.me/staking247"><i className="fab fa-telegram"></i></a>
            <a href="https://github.com/cffls"><i className="fab fa-github"></i></a>
          </div>
          <div className="container">
            <div className="row">
              <div>
                <p>&copy; Staking<sub>247</sub> 2024. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>
    </div>
  );
}

export default App;
